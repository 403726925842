import { ChainId, useEthers } from "@usedapp/core";
import React, { useState, useEffect } from "react";
import Web3 from "web3";
import abi from "../../abi/contractABI.json";
import colAbi from "../../abi/collectionAbi.json";
import rewardAbi from "../../abi/rewardToken.json";
// import { Modal } from "react-responsive-modal";
import NftInventoryModal from "../Modal/NftInventoryModal";
import StakingPoolInventoryModal from "../Modal/StakingPoolInventoryModal";
import { useConnectWallet } from "@web3-onboard/react";
import Countdown from "react-countdown";
import { useLazyQuery, useMutation } from "@apollo/client";
import { collectionData } from "../../graphql/collections/collectionQueries";
import { useNavigate, useParams } from "react-router";
import { updateCollection } from "../../graphql/collections/collectionMutations";
import { networkHashMap } from "../common/constants";
import { useSetChain } from "@web3-onboard/react";
import Modal from "@mui/material/Modal";

const StakingPool = ({ renderData }) => {
  // MUI Popup Start UseState
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => {
  //   setOpen(true);
  // };
  // const handleClose = () => setOpen(false);
  // MUI Popup End UseState
  const [{ connectedChain, chains }] = useSetChain();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
  const [reward, setReward] = useState(null);
  const [symbol, setSymbol] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalStaked, setTotalStaked] = useState(0);
  const [stakingFee, setStakingFee] = useState(null);
  const [withdrawFee, setWithdrawFee] = useState(null);
  const [claimFee, setClaimFee] = useState(null);
  const [isCompleted, setIsCompleted] = useState(false);
  const [rewardsPerWeek, setRewardsPerWeek] = useState();
  const [rewardsDuration, setRewardsDuration] = useState(null);
  const [rewardAmount, setRewardAmount] = useState(null);
  const [duration, setDuration] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  // const [boostedCount, setBoostedCount] = useState(null);
  const [chainId, setChainId] = useState(null);
  const [decimals, setDecimals] = useState(null);
  // const [display, setDisplay] = useState("null");

  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const { contract_address, collection_address, token_address } = renderData;

  const web3 = new Web3(wallet?.provider);
  const web3B = new Web3(
    process.env.REACT_APP_ENVIRONMENT == "DEV"
      ? process.env.REACT_APP_CRONOS_TESTNET_RPC
      : process.env.REACT_APP_CRONOS_RPC
  );
  const contractInstance = new web3.eth.Contract(abi);
  const contractInstanceB = new web3B.eth.Contract(abi);
  contractInstanceB.options.address = contract_address?.toLowerCase();

  const collectionContractInstance = new web3.eth.Contract(colAbi);
  const rewardContractInstance = new web3B.eth.Contract(rewardAbi);
  contractInstance.options.address = contract_address?.toLowerCase();
  collectionContractInstance.options.address =
    collection_address?.toLowerCase();
  rewardContractInstance.options.address = token_address?.toLowerCase();

  // set the chargeStake, chargeWithdraw, chargeClaim needs to be set.
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      setIsCompleted(true);
      return (
        <span className="complete_countdown_text">Countdown complete!</span>
      );
    } else {
      return (
        <span className="time_part">
          {days}d {hours}h:{minutes}m:{seconds}s
        </span>
      );
    }
  };

  useEffect(() => {
    const body = document.querySelector("html");
    body.style.overflow = isModalOpen ? "hidden" : "scroll";
  }, [isModalOpen]);

  const navigate = useNavigate();

  const [UpdateCollection] = useMutation(updateCollection);

  const dateInputHandler = (e) => {
    console.log(
      "new time",
      parseInt((new Date().getTime() + e.target.value * 1000) / 1000)
    );

    setDuration(parseInt(new Date().getTime() + e.target.value * 1000));
  };

  const rewardsDurationHandler = () => {
    setBtnLoading(true);
    if (duration) {
      UpdateCollection({
        variables: {
          payload: {
            _id: renderData._id,
            expire_date: `${duration}`,
          },
        },
        onCompleted: (data) => {
          console.log("dataUpdated", data);
          navigate("/");
        },
      });
    }
  };

  const calculateGas = async () => {
    const res = await fetch(
      `https://api.owlracle.info/v3/cro/gas?apikey=bdf6a9b37f744fd9b5f094b2196266f8`
    );
    const data = await res.json();
    console.log(
      web3.utils.toWei(data.speeds[2].maxFeePerGas.toString(), "gwei")
    );
    return data;
  };

  // useEffect(() => {
  //   console.log("wallet", wallet);
  // }, [wallet]);

  const handleStake = async (selectedNfts) => {
    collectionContractInstance.methods
      .isApprovedForAll(wallet?.accounts[0]?.address, contract_address)
      .call()
      .then(async (data) => {
        if (!data) {
          collectionContractInstance.methods
            .setApprovalForAll(contract_address, true)
            .send({
              from: wallet?.accounts[0]?.address,
              // gas: sessionStorage.getItem("WalletConnect") ? 160000 : "",
            })
            .then(async (data) => {
              const params = [
                collection_address.toLowerCase(),
                [...selectedNfts],
              ];
              const gasEstimate = await contractInstance.methods["stake"](
                ...params
              ).estimateGas({
                from: wallet?.accounts[0]?.address,
                value: selectedNfts.length * stakingFee,
              });
              console.log("gasEstimate", gasEstimate);
              const gasLimit = gasEstimate * 2;
              const tx = await contractInstance.methods["stake"](...params)
                .send({
                  from: wallet?.accounts[0]?.address,
                  value: selectedNfts.length * stakingFee,
                  gas: gasLimit,
                })
                .then(async (data) => {
                  console.log("tx", data);
                  // const receipt = await web3.eth.getTransactionReceipt(
                  //   tx.transactionHash
                  // );
                  // console.log("receipt", receipt.status);
                  setIsModalOpen(false);
                  setIsLoading(false);
                  getTotalStaked();
                })
                .catch((err) => {
                  console.log("err", err);
                  setIsLoading(false);
                });
              // const gasFee = await calculateGas();
              // contractInstance.methods
              //   .stake(collection_address.toLowerCase(), [...selectedNfts])
              //   .send({
              //     from: wallet?.accounts[0]?.address,
              //     value: selectedNfts.length * stakingFee,
              //     // value:
              //     //   boostedCount > 0 ? "0" : selectedNfts.length * stakingFee,
              //     gasLimit:
              //       wallet.label != "MetaMask" &&
              //       (selectedNfts.length == 1
              //         ? 262500
              //         : selectedNfts.length * 131250),

              //     gasPrice: web3.utils.toWei(
              //       gasFee.speeds[2].maxFeePerGas.toString(),
              //       "gwei"
              //     ),
              //   })
              //   .then((receipt) => {
              //     console.log("receipt=======>", receipt);
              //     setIsModalOpen(false);
              //     setIsLoading(false);
              //     getTotalStaked();
              //   })
              //   .catch((err) => {
              //     console.log("error=======>1", err);
              //     setIsLoading(false);
              //   });
            })
            .catch((err) => {
              console.log("error=======>2", err);
              setIsLoading(false);
            });
        } else {
          const params = [collection_address.toLowerCase(), [...selectedNfts]];
          const gasEstimate = await contractInstance.methods["stake"](
            ...params
          ).estimateGas({
            from: wallet?.accounts[0]?.address,
            value: selectedNfts.length * stakingFee,
          });
          console.log("gasEstimate", gasEstimate);
          const gasLimit = gasEstimate * 2;
          const tx = await contractInstance.methods["stake"](...params)
            .send({
              from: wallet?.accounts[0]?.address,
              value: selectedNfts.length * stakingFee,
              gas: gasLimit,
            })
            .then(async (data) => {
              console.log("tx", data);
              // const receipt = await web3.eth.getTransactionReceipt(
              //   tx.transactionHash
              // );
              // console.log("receipt", receipt.status);
              setIsModalOpen(false);
              setIsLoading(false);
              getTotalStaked();
            })
            .catch((err) => {
              console.log("err", err);
              setIsLoading(false);
            });

          // const gasFee = await calculateGas();
          // contractInstance.methods
          //   .stake(collection_address.toLowerCase(), [...selectedNfts])
          //   .send({
          //     from: wallet?.accounts[0]?.address,
          //     value: selectedNfts.length * stakingFee,
          //     // value: boostedCount > 0 ? "0" : selectedNfts.length * stakingFee,
          //     gasLimit:
          //       wallet.label != "MetaMask" &&
          //       (selectedNfts.length == 1
          //         ? 262500
          //         : selectedNfts.length * 131250),

          //     gasPrice: web3.utils.toWei(
          //       gasFee.speeds[2].maxFeePerGas.toString(),
          //       "gwei"
          //     ),
          //   })
          //   .then((receipt) => {
          //     console.log("receipt=======>", receipt);
          //     setIsModalOpen(false);
          //     setIsLoading(false);
          //     getTotalStaked();
          //   })
          //   .catch((err) => {
          //     console.log("error=======>3", err);
          //     setIsLoading(false);
          //   });
        }
      });
  };

  const handleWithdraw = async (selectedNfts) => {
    const params = [collection_address.toLowerCase(), [...selectedNfts]];
    const gasEstimate = await contractInstance.methods["withdraw"](
      ...params
    ).estimateGas({
      from: wallet?.accounts[0]?.address,
      value: selectedNfts.length * withdrawFee,
    });
    console.log("gasEstimate", gasEstimate);
    const gasLimit = gasEstimate * 2;
    const tx = await contractInstance.methods["withdraw"](...params)
      .send({
        from: wallet?.accounts[0]?.address,
        value: selectedNfts.length * withdrawFee,
        gas: gasLimit,
      })
      .then(async (data) => {
        console.log("tx", data);
        // const receipt = await web3.eth.getTransactionReceipt(
        //   tx.transactionHash
        // );
        // console.log("receipt", receipt.status);
        setIsWithdrawModalOpen(false);
        setIsLoading(false);
        getTotalStaked();
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
    // const gasFee = await calculateGas();

    // contractInstance.methods
    //   .withdraw(collection_address, [...selectedNfts])
    //   .send({
    //     from: wallet?.accounts[0]?.address,
    //     value: selectedNfts.length * withdrawFee,
    //     // value: boostedCount > 1 ? "0" : selectedNfts.length * withdrawFee,
    //     // gas: selectedNfts.length == 1 ? 350000 : selectedNfts.length * 180000,
    //     gasLimit:
    //       wallet.label != "MetaMask" &&
    //       (selectedNfts.length == 1 ? 262500 : selectedNfts.length * 131250),
    //     gasPrice: web3.utils.toWei(
    //       gasFee.speeds[2].maxFeePerGas.toString(),
    //       "gwei"
    //     ),
    //   })
    //   .then((receipt) => {
    //     console.log("receipt=======>", receipt);
    //     setIsWithdrawModalOpen(false);
    //     setIsLoading(false);
    //     getTotalStaked();
    //   })
    //   .catch((err) => {
    //     console.log("error=======>", err);
    //     setIsLoading(false);
    //   });
  };
  const handleClaim = async () => {
    setLoading(true);
    const gasEstimate = await contractInstance.methods[
      "claimReward"
    ]().estimateGas({
      from: wallet?.accounts[0]?.address,
      value: claimFee,
    });
    contractInstance.methods
      .claimReward()
      .send({
        from: wallet?.accounts[0]?.address,
        value: claimFee,
        gas: gasEstimate * 2,
      })
      .then((receipt) => {
        console.log("receipt=======>", receipt);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error=======>", err);
        setLoading(false);
      });
  };

  const getTotalStaked = () => {
    contractInstanceB.methods
      .totalStaked(collection_address)
      .call()
      .then((data) => {
        setTotalStaked(data);
        setIsLoading(false);
      });
  };

  // useEffect(() => {
  //   if (wallet && wallet.label) {
  //     setDisplay(wallet.label + `${wallet.label != "MetaMask"}`);
  //   }
  // }, [wallet]);

  useEffect(() => {
    if (wallet?.accounts[0]?.address) {
      contractInstance.methods
        .earned(collection_address, wallet?.accounts[0]?.address)
        .call()
        .then((data) => {
          setReward(data);
        });
    }
    getTotalStaked();
    contractInstanceB.methods
      .stakeFee()
      .call()
      .then((data) => {
        setStakingFee(data);
      })
      .catch((err) => {
        console.log("err", err);
      });

    contractInstanceB.methods
      .withdrawFee()
      .call()
      .then((data) => {
        setWithdrawFee(data);
      })
      .catch((err) => {
        console.log("err", err);
      });

    contractInstanceB.methods
      .claimFee()
      .call()
      .then((data) => {
        setClaimFee(data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, [renderData]);

  useEffect(() => {
    const amount = new Promise((res, rej) => {
      contractInstanceB.methods
        .rewardAmount()
        .call()
        .then((receipt) => {
          // console.log("receipt", receipt);
          // setRewardAmount(receipt);
          res(receipt);
        });
    });

    const duration = new Promise((res, rej) => {
      contractInstanceB.methods
        .rewardsDuration()
        .call()
        .then((receipt) => {
          // setRewardsDuration(receipt);
          res(receipt);
        });
    });
    Promise.all([amount, duration])
      .then(([result1, result2]) => {
        // console.log(result1, result2);
        setRewardAmount(result1);
        setRewardsDuration(result2);
      })
      .catch((error) => console.error(error));
  }, [renderData, wallet]);

  useEffect(() => {
    setRewardsPerWeek(
      parseInt(rewardAmount) / (parseInt(rewardsDuration) / 604800)
    );
  }, [rewardsDuration]);

  useEffect(() => {
    rewardContractInstance.methods
      .symbol()
      .call()
      .then((data) => {
        setSymbol(data);
      });

    rewardContractInstance.methods
      .decimals()
      .call()
      .then((data) => {
        // console.log(data);
        setDecimals(data);
      });
  }, [reward]);

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT == "DEV") {
      setChainId(ChainId.CronosTestnet);
    } else {
      setChainId(ChainId.Cronos);
    }
  }, [wallet]);

  const modalHandler = () => {
    setIsModalOpen(!isModalOpen);
  };

  const withdrawModalHandler = () => {
    setIsWithdrawModalOpen(!isWithdrawModalOpen);
  };

  // useEffect(() => {
  //   if (wallet) {
  //     contractInstanceB.methods
  //       .isBoostActive()
  //       .call()
  //       .then((data) => {
  //         console.log("isBoostActive", data);
  //         if (data) {
  //           contractInstanceB.methods
  //             .getBoostCount(wallet?.accounts[0]?.address)
  //             .call()
  //             .then((data) => {
  //               console.log("data", data);
  //               setBoostedCount(data);
  //             })
  //             .catch((err) => {
  //               console.log("err", err);
  //             });
  //         }
  //       })
  //       .catch((err) => {
  //         console.log("err", err);
  //       });
  //   }
  // }, [wallet]);

  return (
    <>
      {/* {true && ( */}
      <section className="stack_two_new_list">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="stackCardWrapper">
                <div className="card-header bg-inherit border-0 p-0">
                  <h2 className="m-0">
                    <button
                      className="staking-btn d-block text-left w-100 py-4"
                      type="button"
                      data-toggle="collapse"
                      data-target={`#collapse${renderData._id}`}
                    >
                      <div className="row">
                        <div className="col-12 col-md-8">
                          <div className="media flex-column flex-md-row">
                            <img
                              className="avatar-max-lg"
                              src={
                                renderData.picture
                                  ? renderData.picture
                                  : "/img/placeholder.png"
                              }
                              alt=""
                            />
                            <div className="content media-body  ml-md-4 w-100">
                              <h4 className="m-0">{renderData.name}</h4>
                              <span className="symbol">
                                {renderData.symbol}
                              </span>
                              {/* {wallet && <span>{display}</span>} */}
                              <span className="address">
                                address:
                                <a
                                  href={
                                    process.env.REACT_APP_ENVIRONMENT == "DEV"
                                      ? `https://testnet.cronoscan.com/address/${contract_address}`
                                      : `https://cronoscan.com/address/${contract_address}`
                                  }
                                  target={"_blank"}
                                >
                                  {" "}
                                  {/* {contract_address} */}
                                  {contract_address.substring(0, 5)}...
                                  {contract_address.substring(37, 42)}
                                </a>
                              </span>

                              <p className="description">
                                {renderData.description}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <span className="time_data">{totalStaked} NFTs</span>
                          {renderData.expire_date && (
                            <Countdown
                              date={new Date(parseInt(renderData?.expire_date))}
                              intervalDelay={0}
                              precision={3}
                              renderer={renderer}
                            />
                          )}
                          {wallet &&
                            renderData.creator_address ==
                              wallet.accounts[0].address.toLowerCase() && (
                              <>
                                <input
                                  className="rewardDurationInput"
                                  type="number"
                                  placeholder="Duration in Seconds"
                                  onChange={dateInputHandler}
                                />
                                <button
                                  className="btn input-btn mt-2 rewardDurationBtn"
                                  onClick={rewardsDurationHandler}
                                  disabled={!duration ? true : false}
                                >
                                  {btnLoading ? (
                                    <div className="col-12 text-center">
                                      <div
                                        className="spinner-border"
                                        role="status"
                                      >
                                        <span className="visually-hidden"></span>
                                      </div>
                                    </div>
                                  ) : (
                                    "SetRewardDuration"
                                  )}
                                </button>
                              </>
                            )}
                        </div>
                      </div>
                    </button>
                  </h2>
                </div>
                <div
                  id={`collapse${renderData._id}`}
                  className="collapse show"
                  data-parent="#gameon-accordion"
                >
                  {/* Card Body */}

                  <div className="card-body">
                    {wallet?.accounts[0]?.address && wallet ? (
                      chainId !== networkHashMap[connectedChain?.id] ? (
                        <div>
                          <span>Please Switch Your Network!</span>
                        </div>
                      ) : (
                        <div className="row">
                          {/* Single Staking Item */}
                          {!isCompleted && (
                            <div className="col-12 col-md-3 single-staking-item input-box">
                              <span className="item-title mb-2">Deposit</span>
                              <div className="input-area d-flex flex-column">
                                <button
                                  // href="#"
                                  className="btn input-btn mt-2"
                                  // onClick={modalHandler}

                                  onClick={modalHandler}
                                >
                                  Deposit
                                </button>
                              </div>
                            </div>
                          )}
                          {/* <Modal
                            open={isModalOpen}
                            onClose={() => setIsModalOpen(false)}
                            center
                          >
                            <NftInventoryModal
                              renderData={renderData}
                              handleStake={handleStake}
                              isLoading={isLoading}
                              setIsLoading={setIsLoading}
                            />
                          </Modal> */}
                          <Modal open={isModalOpen} onClose={modalHandler}>
                            <NftInventoryModal
                              renderData={renderData}
                              handleStake={handleStake}
                              isLoading={isLoading}
                              setIsLoading={setIsLoading}
                              handleClose={modalHandler}
                            />
                          </Modal>
                          <Modal
                            open={isWithdrawModalOpen}
                            onClose={() => setIsWithdrawModalOpen(false)}
                          >
                            <StakingPoolInventoryModal
                              renderData={renderData}
                              handleWithdraw={handleWithdraw}
                              isLoading={isLoading}
                              setIsLoading={setIsLoading}
                              handleClose={() => setIsWithdrawModalOpen(false)}
                            />
                          </Modal>
                          {/* Single Staking Item */}
                          <div className="col-12 col-md-3 single-staking-item input-box">
                            <span className="item-title mb-2">Withdraw</span>
                            <div className="input-area d-flex flex-column">
                              <button
                                // href="#"
                                className="btn input-btn mt-2"
                                onClick={withdrawModalHandler}
                              >
                                Withdraw
                              </button>
                            </div>
                          </div>
                          {/* Single Staking Item */}
                          {/* {!isCompleted && ( */}
                          <div className="col-12 col-md-3 single-staking-item input-box">
                            <span className="item-title mb-2">
                              Pending rewards
                            </span>
                            <div className="input-area d-flex flex-column">
                              <h4 className="price m-0">
                                {" "}
                                {decimals && reward > 0
                                  ? parseFloat(
                                      reward * 10 ** -decimals
                                    ).toLocaleString({
                                      maximumFractionDigits: 2,
                                    })
                                  : 0}{" "}
                                {symbol && symbol}
                              </h4>
                              <span className="reward my-2">
                                {/* Rewards are depleted, ask admins to fund it. */}
                              </span>
                              <button
                                // href="#"
                                className="btn input-btn mt-2"
                                onClick={handleClaim}
                              >
                                {loading ? (
                                  <div className="col-12 text-center">
                                    <div
                                      className="spinner-border"
                                      role="status"
                                    >
                                      <span className="visually-hidden"></span>
                                    </div>
                                  </div>
                                ) : (
                                  "Claim"
                                )}
                              </button>
                            </div>
                          </div>
                          {/* )} */}
                          <div className="col-12 col-md-3 single-staking-item input-box">
                            <span className="item-title mb-2">Pool Rate</span>
                            <div className="input-area d-flex flex-column">
                              <h4 className="price m-0">
                                {rewardsPerWeek
                                  ? (
                                      rewardsPerWeek *
                                      10 ** -decimals
                                    ).toLocaleString("en-US", {
                                      maximumFractionDigits: 2,
                                    })
                                  : 0}{" "}
                                {symbol && symbol}/Week
                              </h4>
                            </div>
                          </div>
                        </div>
                      )
                    ) : (
                      <div>
                        <span>Please Connect Your Wallet!</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* )} */}
    </>
  );
};

const StakingNFT = () => {
  const [renderData, setRenderData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // const { contract_address, collection_address, token_address } = renderData;

  const [GetCollection, { loading }] = useLazyQuery(collectionData, {
    fetchPolicy: "network-only",
  });
  const params = useParams();

  useEffect(() => {
    GetCollection({
      variables: {
        filters: {
          contract_address: params.id,
        },
      },
      onCompleted: (data) => {
        setRenderData(data.Collections[0]);
        setIsLoading(false);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  }, [params]);

  return (
    <>
      {isLoading && (
        <section className="loader_sec stakingPool_loader">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="loader_wrapper">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <div>{renderData && <StakingPool renderData={renderData} />}</div>
    </>
  );
};

export default StakingNFT;
