import React, { useEffect, useState } from "react";
import Web3 from "web3";
import abi from "../../abi/contractABI.json";
import { tokenByteCode } from "../../abi/constants";
import { convertBase64 } from "../common/utils";
import { useMutation } from "@apollo/client";
import { createCollection } from "../../graphql/collections/collectionMutations";
import { useEthers } from "@usedapp/core";
import { useNavigate } from "react-router";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";
import { useConnectWallet } from "@web3-onboard/react";

const DragAndDrop = ({ formData, setFormData }) => {
  const [errorMsg, setErrorMsg] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const imageDropHandler = async (file) => {
    const base64 = await convertBase64(file);
    setPreviewImage(file);
    setFormData({ ...formData, image: base64 });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/gif": [],
    },
    maxSize: 500000,
    maxFiles: 1,
    onDrop: async (files) => {
      const file = files[0];

      imageDropHandler(file);
    },
    onDropRejected: (abc) => {
      console.log(abc[0].errors[0].message);
      setErrorMsg(abc[0].errors[0].message);
    },
  });

  // const previewImage = objectKeyFetch(formData.avatar_url);

  return (
    <div className="drag_drog admin_form_dropzone_wrapper">
      {!formData?.image ? (
        <div {...getRootProps({ className: "dropzone admin_form_dropzone" })}>
          {/* <label for="file-upload" class="admin_upload_label">
            <i class="fa fa-cloud-upload"></i> Upload a picture
          </label> */}
          <input {...getInputProps()} name="avatar_url" type="file" />
          {/* <img src={CirclePlus} alt="" /> */}
          <p className="admin_dropzone">
            Drag 'n' drop some files here,
            <br />
            or click to select files
            <br />
            <i className="fas fa-plus"></i>
          </p>

          {errorMsg && (
            <p
              style={{
                marginTop: "50px",
                color: "#ff0000 !important",
                fontWeight: "600",
              }}
            >
              {errorMsg}
            </p>
          )}
        </div>
      ) : (
        <>
          <div className="upload_img_wrapper">
            <span>
              <img
                className="uploaded_image"
                src={previewImage && URL.createObjectURL(previewImage)}
                height={100}
                style={{ borderRadius: "10px" }}
              />{" "}
              <span
                onClick={() => {
                  setFormData({ ...formData, image: "" });
                  setErrorMsg(null);
                }}
              >
                <i className="fas fa-plus"></i>
              </span>
            </span>
          </div>

          {/* <button
            className="btn btn-bordered active"
            onClick={() => {
              setFormData({ ...formData, image: "" });
              setErrorMsg(null);
            }}
          >
            Delete
          </button> */}
        </>
      )}
    </div>
  );
};

const Admin = () => {
  // const [image, setImage] = useState(null);
  const [formData, setFormData] = useState({});
  const [newContract, setNewContract] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // const { account } = useEthers();
  const navigate = useNavigate();
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const [CreateCollection] = useMutation(createCollection);
  const userData = useSelector((state) => state?.state);

  useEffect(() => {
    if (userData.role != "admin") {
      navigate("/");
    }
  }, [userData]);

  useEffect(() => {
    if (newContract) {
      CreateCollection({
        variables: {
          payload: {
            name: formData.name,
            symbol: formData.symbol,
            description: formData.description,
            contract_address: newContract,
            creator_address: userData.walletAddress,
            picture: formData.image,
            token_address: formData.ERC20Address,
            collection_address: formData.collectionAddress,
          },
        },
        onCompleted: (data) => {
          console.log("data", data);
        },
      }).then(() => {
        navigate("/");
      });
    }
  }, [newContract]);

  // useEffect(() => {
  //   console.log("formData", formData);
  // }, [formData]);

  useEffect(() => {
    if (userData.walletAddress && userData) {
      if (userData.role != "admin") {
        navigate("/");
      }
    }
  }, [userData.walletAddress]);

  const submitHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const web3 = new Web3(wallet?.provider);
    const standardtokenContract = new web3.eth.Contract(abi);
    standardtokenContract
      .deploy({
        data: "0x" + tokenByteCode,
        arguments: [
          formData.ERC20Address,
          [formData.collectionAddress],
          [formData.ratio],
        ],
      })
      .send(
        {
          from: userData.walletAddress,
          gasPrice: "1915983702291",
        },
        function (error, transactionHash) {
          if (error) {
            console.error(error);
            return;
          }
          console.log("Transaction Hash :", transactionHash);
        }
      )
      .on("confirmation", function () {
        return;
      })
      .then(function (newContractInstance) {
        if (!newContractInstance.options.address) {
          console.log(newContractInstance);
          return;
        }
        console.log(
          "Deployed Contract Address : ",
          newContractInstance.options.address
        );
        setNewContract(newContractInstance.options.address);
      })
      .catch(function (error) {
        console.error("error", error);
        setIsLoading(false);
      });
  };

  const inputChangeHandler = ({ target: { name, value } }) => {
    formData[name] = value.trim();
    let newObj = { ...formData };
    setFormData(newObj);
  };

  return (
    <section className="apply-area contact">
      <div className="container">
        <div className="row stack_row_box">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-7">
                <div className="apply-form card no-hover">
                  {/* Intro */}
                  <div className="intro d-flex justify-content-between align-items-end mb-4">
                    <div className="intro-content">
                      <h3 className="mt-3 mb-0">Admin</h3>
                    </div>
                  </div>
                  <form id="contact-form">
                    <div className="form-group">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="boredape"
                        required="required"
                        onChange={inputChangeHandler}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="symbol">Symbol</label>
                      <input
                        type="text"
                        id="symbol"
                        name="symbol"
                        placeholder="XYZ"
                        required="required"
                        onChange={inputChangeHandler}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="description">Description</label>
                      <textarea
                        id="description"
                        name="description"
                        placeholder="Description"
                        cols={30}
                        rows={3}
                        required="required"
                        defaultValue={""}
                        onChange={inputChangeHandler}
                      />
                      <small className="form-text mt-2">
                        Briefly describe what you need
                      </small>
                    </div>

                    <div className="form-group">
                      <label htmlFor="ERC20Address">ERC20 Address</label>
                      <input
                        type="text"
                        id="ERC20Address"
                        name="ERC20Address"
                        placeholder="ERC20 Address"
                        required="required"
                        onChange={inputChangeHandler}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="collectionaddress">
                        Collection Address
                      </label>
                      <input
                        type="text"
                        id="collectionaddress"
                        name="collectionAddress"
                        placeholder="Collection Address"
                        required="required"
                        onChange={inputChangeHandler}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="ratio">Ratio</label>
                      <input
                        type="number"
                        id="ratio"
                        name="ratio"
                        placeholder="Ratio"
                        required="required"
                        onChange={inputChangeHandler}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="picture">Picture</label>
                      <DragAndDrop
                        formData={formData}
                        setFormData={setFormData}
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn btn-bordered active"
                      onClick={submitHandler}
                      disabled={
                        !formData.name ||
                        !formData.symbol ||
                        !formData.description ||
                        !formData.ERC20Address ||
                        !formData.collectionAddress ||
                        !formData.ratio
                      }
                    >
                      {isLoading ? (
                        <div className="col-12 text-center">
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden"></span>
                          </div>
                        </div>
                      ) : (
                        "submit"
                      )}
                    </button>
                  </form>
                  <p className="form-message" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Admin;
